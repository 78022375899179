import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import raniyah_img_1 from '../../../images/RaniyahCopeland.jpg';
import raniyah_img_1_mobile from '../../../images/RaniyahCopeland_mobile.jpg';

const fullName = "Raniyah Copeland";

const copyList = [

    {
        type: "image",
        content: {
            src: raniyah_img_1,
            mobileSrc: raniyah_img_1_mobile,
            alt: "Raniyah Copeland",
            caption: "Raniyah Copeland"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Raniyah Copeland, founder of Equity & Impact Solutions and former CEO of the Black AIDS Institute"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Crusader"
    // },

    {
        type: 'text',
        content: "Raniyah Copeland (formerly Abdus-Samad) is the product of an unapologetically Black environment. “My parents came from a family that traced its roots to runaway slaves.” Raniyah’s parents instilled in her that “we as Black people owed it to future generations to take our freedom.” They gave rise to a freedom fighter. Copeland’s passion for advocating grew while attending UC Berkeley. “I spent as much time in class as I did in administrative offices, advocating for Black student admissions and resources.”"
    },
    {
        type: 'text',
        content: `Healthcare disparities always piqued her interest. “In high school, my mom got breast cancer, and I remember 
        her physicians not understanding what it meant to cut her locs. There was a lack of cultural sensitivity." In college, "I remember 
        this white professor framing the Tuskegee Syphilis Study as if it were a medical accomplishment. But learning 
        about HIV in my Black Studies classes was a different experience.”`
    },
    {
        type: "text",
        content: `After college, Raniyah worked at Planned Parenthood. There, she worked alongside colleagues as they 
        gave a positive HIV diagnosis. “When the results were delivered, it was almost as if the patient 
        expected it. He shared that he felt that HIV was inevitable as a young Black gay man.” That reaction made her 
        career goal clear: “I wanted to ignite an upswell of greater community health for my people, specifically 
        those impacted by HIV.” Seeing a job posting for an entry-level coordinator at the 
        Black AIDS Institute (BAI),* “I was like, ‘That’s it.’”`
    },

    {
        type: "blockquote",
        content: "“I wanted to ignite an upswell of greater community health for my people, specifically those impacted by HIV.”",
        author: fullName
    },
    {
        type: "text",
        content: `There, she committed herself to seeing that HIV did not feel inevitable or like a death sentence for Black 
        people. For over a decade, she worked alongside BAI’s founder, Phill Wilson, before rising 
        to senior leadership, becoming the organization’s first Black woman president and CEO. She built a track 
        record for driving impactful initiatives that helped address the needs of the Black community, like getting 
        HIV curricula integrated into Historically Black Colleges and Universities programs and training 
        and amplifying the voices of Black advocates across the nation. Growth and expansion then 
        became her focus, and she began a remarkably successful chapter for the national, Black-led 
        organization. “I dug in and was able to more than double the organization’s budget, expand 
        national and local programs, and develop critical institutional relationships.”`
    },
    {
        type: "text",
        content: "As the founder of Equity & Impact Solutions, where she currently works, she offers organizations her unique set of technical skills for equity advancement, community engagement, and culturally relevant programming—all pathways to helping end HIV. “I’m deeply inspired by my clients, who I get to co-create a better future with and alongside.” What makes her journey even sweeter: “I have mountains of support. Each day, I feel my parents, family, community, and ancestors with me. I’m blessed I get to dedicate my life to helping Black people live long and healthy lives.”"
    },
    {
      type: "disclaimer",
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">RANIYAH COPELAND"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Equity & Impact Solutions"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    }
]

class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Raniyah Copeland, The Crusader"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      


      </div>
    );
  }
}

export default Content;
